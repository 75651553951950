import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SystemUpdateService {
  private lastUpdatesSubject = new BehaviorSubject<any[]>([]); // تخزين آخر التحديثات
  lastUpdates$ = this.lastUpdatesSubject.asObservable(); // تعريضها كمصدر للبيانات

  setLastUpdates(updates: any[]) {
    this.lastUpdatesSubject.next(updates); // تحديث القيم
  }
}
