// system-update-modal.component.ts
import { Component, OnInit } from '@angular/core';
import { LookupService } from '@foh/shared/services/lookup.service';
import { SystemUpdateService } from '@foh/shared/services/SystemUpdateData/system-update.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-system-update-modal',
  templateUrl: './system-update-modal.component.html'
})
export class SystemUpdateModalComponent implements OnInit {

  lastUpdates: {title: string, description: string}[] = [];

  constructor(
    private SystemUpdate: SystemUpdateService,
    public activeModal: NgbActiveModal,
    private lookups: LookupService
  ) {}

  ngOnInit(): void {
    this.lastUpdates = this.lookups["updates"];
    // this.SystemUpdate.lastUpdates$.subscribe(data => {
    //   if(data) {
    //     this.lastUpdates = data;
    //   }
    // });
  }
}