import { Injectable } from '@angular/core';
import { Globals } from '@foh/globals';
import { AuthService } from "@foh/shared/auth/auth.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class FohmoduleSelectorService {
  currentSelectedModule: string;
  availableModules: any;
  constructor(private globals: Globals, private authService: AuthService, private router: Router) {
    this.availableModules = ["Rez", "Pass", "Ordering", "Queue", "Citadel"];
    this.refreshCurrentModule();
  }

  refreshCurrentModule() {
    var hasCurrentFOHModule = localStorage.getItem(this.globals.STORAGE_PREFIX + "currentfohmodule");
    if (hasCurrentFOHModule == null) {
      if (this.authService.accessToFohModule("rez")) {
        this.setModule("rez");
      }
      else if (this.authService.accessToFohModule("pass")) {
        this.setModule("pass");

      }
      else if (this.authService.accessToFohModule("ordering")) {
        this.setModule("ordering");
      }
      else if (this.authService.accessToFohModule("queue")) {
        this.setModule("queue");
      }
      else if (this.authService.accessToFohModule("wedding")) {
        this.setModule("wedding");
      }
    } else {
      this.currentSelectedModule = hasCurrentFOHModule;
      this.authService.setModule(this.currentSelectedModule);
    }
  }

  setModule(module) {
    this.currentSelectedModule = module;
    this.authService.setModule(module);
    localStorage.setItem(this.globals.STORAGE_PREFIX + "currentfohmodule", module);
  }

  changeModule(module) {
    this.setModule(module);
    switch (module) {
      case "rez":
        this.router.navigate(["rez/dashboard"]).then(() => {
          window.location.reload();
        });
        break;
      case "pass":
        this.router.navigate(["pass/events/list"]).then(() => {
          window.location.reload();
        });
        break;
      case "ordering":
        this.router.navigate(["ordering/dashboard"]).then(() => {
          window.location.reload();
        });
        break;
      case "queue":
        this.router.navigate(["customers/list"]).then(() => {
          window.location.reload();
        });
        break;
      case "wedding":
        this.router.navigate(["customers/list"]).then(() => {
          window.location.reload();
        });
        break;
    }
  }

  getAvailableModules() {
    return this.availableModules;
  }

  getCurrentModule() {
    return this.currentSelectedModule;
  }

  isModuleActive(module: string): boolean {
    return this.currentSelectedModule === module;
  }

}
