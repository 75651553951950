import { Component, OnInit } from "@angular/core";
import { LookupService } from "@foh/shared/services/lookup.service";
import { FohmoduleSelectorService } from "@foh/shared/services/fohmodule-selector.service";
import { trigger, transition, style, query, animate, group } from '@angular/animations';

@Component({
  selector: "app-full-layout",
  templateUrl: "./full-layout.component.html",
  styleUrls: ["./full-layout.component.scss"],
  animations: [
    trigger('fadeAnimation', [
      // Apply the animation for any route change.
      transition('* <=> *', [
        // Set a fixed position for both entering and leaving elements to avoid layout shifts.
        query(':enter, :leave', [
          style({ position: 'fixed', width: '100%' })
        ], { optional: true }),
        group([
          // Animate the leaving component: fade it out.
          query(':leave', [
            style({ opacity: 1 }),
            animate('300ms ease-out', style({ opacity: 0 }))
          ], { optional: true }),
          // Animate the entering component: fade it in.
          query(':enter', [
            style({ opacity: 0 }),
            animate('300ms ease-out', style({ opacity: 1 }))
          ], { optional: true })
        ])
      ])
    ])
  ]
})
export class FullLayoutComponent implements OnInit {
  options = {
    direction: "ltr"
  };
  showSmsWarning: boolean = false;
  showLicenseWarning: boolean = false;
  showInvoiceAlertWarning: boolean = false;
  nextBillingDate: any;
  currentSelectedModule: string;

  constructor(
    private lookupService: LookupService,
    private fohmoduleSelectorService: FohmoduleSelectorService
  ) { }

  ngOnInit() {
    const mCompany = this.lookupService.mcompany;
    if (mCompany.usessms) {
      if (this.lookupService.vars["smsBalance"] <= 100) {
        this.showSmsWarning = true;
      }
    }
    this.showLicenseWarning = this.lookupService.licenseHaveTextWarning;
    this.showInvoiceAlertWarning = this.lookupService.invoiceAlertWarning;
    this.nextBillingDate = this.lookupService.nextBillingDate;
    this.currentSelectedModule = this.fohmoduleSelectorService.getCurrentModule();
  }

  // Helper function to retrieve animation data from the activated route.
  getAnimationData(outlet: any) {
    return outlet.activatedRouteData['animation'] || '';
  }
}
