import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { LookupService } from '../services/lookup.service';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class LookupResolver implements Resolve<any> {
  private lookupCache$: Observable<any>;

  constructor(private lookupService: LookupService) { }

  resolve() {
    if (!this.lookupCache$) {
      this.lookupCache$ = this.lookupService.getLookups().pipe(
        shareReplay(1)
      );
    }
    return this.lookupCache$;
  }
}
