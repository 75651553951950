import { RouteInfo } from './sidebar.metadata';

export const QUEUEGROUTES: RouteInfo[] = [
  {
    path: '/rez/dashboard', title: 'Dashboard', icon: 'foh-icon foh-dashboard', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    alias: "requests", path: '/queue/operations', title: 'Requests', icon: 'foh-reservation foh-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reservationcustomersmanage", "permission_reservationcustomersview"], submenu: []
  },
  {
    alias: "customers", path: '/customers/list', title: 'Profiles', icon: 'foh-icon foh-customers', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reservationcustomersmanage", "permission_reservationcustomersview"], submenu: []
  },
  {
    path: '', title: 'Company Settings', icon: 'foh-icon foh-settings', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_setup_mcompany"],
    submenu: [
      { path: '/mcompany-setup/clientscats', title: 'Clients Categories', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/mcompany-setup/templates/sms', title: 'SMS Templates', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/mcompany-setup/templates/whatsapp', title: 'WhatsApp Templates', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], permissions: ["permission_superadmin"] },
      { path: '/mcompany-setup/slots', title: 'Slots', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/mcompany-setup/tags', title: 'Tags', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/mcompany-setup/terms', title: 'Terms & Conditions', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Settings', icon: 'foh-icon foh-settings', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_setup_mcompany", "permission_configs", "permission_tablesetup"],
    submenu: [
      {
        alias: "configs", path: '/rez/configs', title: 'General Config', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], permissions: ["permission_configs"]
      },
      { path: '/table-setup', title: 'Layout Setup', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/mcompany-setup/table-types', title: 'Table Types', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
];
